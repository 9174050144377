import { getDomain } from '@utils/http'
import { cloudfrontPath } from '@utils/image'

export const CLOUDFRONT_ENV_DOMAIN = {
  dev: 'd1rymue6s04l0d',
  preview: 'd1rymue6s04l0d',
  staging: 'd2c9x2x0n5546t',
  production: 'd1v6fx8fk7eiec',
}

export const BREAKPOINTS = {
  mobile: 640,
  tablet: 768,
  laptop: 1024,
}

export const FREE_TRIAL_LINK = 'https://hr.buzzkuri.com/trial-top'

export const CONTACT_LINK =
  'https://share.hsforms.com/1X8Xk3F-oTtmjeR5ltbuvIA4xn58'

export const NO_IMAGES = {
  rectangle: `/img/shared/no_image_yoko.jpeg`,
}
export const INTERNAL_SERVER_ERROR_PAGE_PROPS = {
  currentPathName: 'サーバーエラーが発生しました',
  heading: '500 Internal Server Error\nサーバーエラーが発生しました',
  description:
    'サーバーの問題でお探しのページを表示できません。再度時間をおいてアクセスしてください。',
}

export const NOT_FOUND_PAGE_PROPS = {
  currentPathName: 'ページが見つかりませんでした',
  heading: '404 Not Found\nページが見つかりませんでした',
  description:
    'お探しのページはURLが正しくないか、ページが削除された可能性があります。',
}

export const ALTERNATE_DATA_AT_ERROR = {
  metaData: {
    title: 'バヅクリ | テレワーク時代のチームビルディング',
    description:
      '「バヅクリ」は、テレワーク企業で働く社員の”つながり”を創るオンラインチームビルディングイベントサービスです。社員の成長やモチベーション向上、コミュニケーション活性化やストレス解消に役立つ、50種類以上のオンライン“アソビ”体験プログラムで企業と人を支援します。',
    ogp: {
      type: 'website',
      title: 'バヅクリ | テレワーク時代のチームビルディング',
      url: `https://${getDomain}/`,
      image: `${cloudfrontPath}/home/img/logo.jpg`,
      description:
        '「バヅクリ」は、テレワーク企業で働く社員の”つながり”を創るオンラインチームビルディングイベントサービスです。社員の成長やモチベーション向上、コミュニケーション活性化やストレス解消に役立つ、50種類以上のオンライン“アソビ”体験プログラムで企業と人を支援します。',
    },
    twitterCard: {
      title: 'バヅクリ | テレワーク時代のチームビルディング',
      card: `${cloudfrontPath}/home/img/logo.jpg`,
      image: `${cloudfrontPath}/home/img/logo.jpg`,
      description:
        '「バヅクリ」は、テレワーク企業で働く社員の”つながり”を創るオンラインチームビルディングイベントサービスです。社員の成長やモチベーション向上、コミュニケーション活性化やストレス解消に役立つ、50種類以上のオンライン“アソビ”体験プログラムで企業と人を支援します。',
    },
  },
  pageContent: {},
  business: {},
}

export const DOCUMENT_DOWNLOAD_LINK_V2 =
  'https://download.buzzkuri.com/document'
