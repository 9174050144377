import React, { CSSProperties, useEffect, useState } from 'react'
import Modal from 'react-modal'

type Props = {
  isOpen: boolean
  contentLabel: string
  customStyles: {
    content: CSSProperties
    overlay: CSSProperties
  }
  children: JSX.Element
  closeModalAction?: () => void
  closeTimeoutMS?: number
  portalClassName: string
}

export const ModalWaper = ({
  isOpen,
  contentLabel,
  customStyles,
  children,
  closeModalAction,
  closeTimeoutMS = 0,
  portalClassName = '',
}: Props): JSX.Element => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  return isBrowser ? (
    <Modal
      {...{ isOpen, contentLabel, closeTimeoutMS, portalClassName }}
      onRequestClose={closeModalAction}
      style={customStyles}
      appElement={document.getElementById('__next') ?? undefined}
    >
      {children}
    </Modal>
  ) : (
    <></>
  )
}
