import { useRouter } from 'next/router'
import React, { useState } from 'react'

export const ProgramExtendedSearchBox = (): JSX.Element => {
  const [searchWord, setsearchWord] = useState('')
  const router = useRouter()

  const screenTransition = () =>
    router.push({
      pathname: '/programs/search',
      query: { searchWord },
    })

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      screenTransition()
    }
  }

  return (
    <div className="flex flex-row justify-between lg:justify-start mb-[6.4vw] lg:mb-[2.7vw] w-[73.6vw] lg:w-[15.2vw] h-[10.7vw] lg:h-[3.1vw] border border-[#F0B430]">
      <div className="w-[67.7vw] lg:w-[11.4vw]">
        <input
          className="lg:p-[0.8vw] px-[2vw] w-full lg:w-[11.5vw] h-full text-[3.5vw] lg:text-[1vw]"
          type="text"
          name="searchWord"
          id="searchWord"
          onChange={(v) => setsearchWord(v.target.value.replace(/　/g, ' '))}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </div>
      <button
        className="w-[13.3vw] lg:w-[3.9vw] bg-[#F0B430] lg:hover:opacity-50 disabled:cursor-not-allowed"
        disabled={!searchWord || !searchWord.match(/\S/g)}
        onClick={screenTransition}
        type="submit"
      >
        <div className="flex justify-center items-center">
          <div className="text-[3.7vw] lg:text-[1.1vw] font-bold text-white">
            検索
          </div>
        </div>
      </button>
    </div>
  )
}
