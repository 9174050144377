type UrlUnit = {
  path: string
  params: string
  jumpId: string
}

export const getUrlUnit = (url: string): UrlUnit => {
  const [path, params, jumpId] = splitJumpId(splitParams(url))
  return { path, params, jumpId }
}

const splitParams = (url: string) => url.split('?')

const splitJumpId = ([path, params]: string[]) =>
  params
    ? [path, ...params.split('#')]
    : [path.split('#')[0], params, path.split('#')[1]]

export const removePrams = (str: string): string => getUrlUnit(str).path

export const addParams = (params: string): string =>
  params ? `?${params}` : ''

export const addJumpId = (jumpId: string): string =>
  jumpId ? `#${jumpId}` : ''
