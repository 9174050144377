import { BaseLayout } from '@components/buzzkuri/shared/layouts/Base'
import React from 'react'
import { Breadcrumb } from './Breadcrumb'
import { Heading } from './Heading'
import { Description } from './Description'
import { ToTopBtn } from './ToTopBtn'
import Head from 'next/head'

type Props = {
  currentPathName: string
  heading: string
  description: string
}

export const ErrorPage = ({
  currentPathName,
  heading,
  description,
}: Props): JSX.Element => {
  const business = {}
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <BaseLayout {...{ business }}>
        <div className="flex mt-[16.5vw] lg:mt-[7.2vw] w-full h-full">
          <main className="overflow-x-hidden w-full">
            <Breadcrumb {...{ currentPathName }} />
            <div className="flex flex-col justify-center items-center mb-[10vw] lg:mb-0 h-[70vw] lg:h-auto">
              <Heading text={heading} />
              <Description text={description} />
              <ToTopBtn />
            </div>
          </main>
        </div>
      </BaseLayout>
    </>
  )
}
