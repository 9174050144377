import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'
import React from 'react'

type Props = {
  href: string
  children: string | JSX.Element
  target: string
}

export const NonLoginUserNavList = ({
  href,
  children,
  target,
}: Props): JSX.Element => (
  <a href={href} target={target}>
    <div className="flex flex-row justify-between items-center h-[11.7vw] lg:hover:opacity-70 easing-up__txt">
      <div className="text-[3.7vw] font-bold text-[#333]">{children}</div>
      <div className="w-[7.5vw] rotate-180">
        <ImageWraper
          src={`${cloudfrontPath}/programs/icons/top/ellipse_arrow_orange.svg`}
          alt="画面遷移アイコン"
          width={20}
          height={20}
        />
      </div>
    </div>
  </a>
)
