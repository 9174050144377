import { ProgramCategoryList } from '@type/buzzkuri/programs-categor'

export const EXTENDED_HEADER = ['目的から探す', 'ジャンルから探す']

export const OBJECTIVE = [
  '新人・若手社員研修',
  'チームビルディング',
  '労働組合レクリエーション',
  'モチベーション向上',
  'テレワークスキルアップ',
  'コミュニケーション活性化',
  '心理的安全性の醸成',
  'エンゲージメント向上',
  '離職防止',
  'メンタルヘルスケア',
]

export const GENRE = [
  'プレゼンテーション',
  'おとなの図工',
  'マインドフルネス',
  'テレワークコミュニケーション',
  '演劇',
  '焚き火',
  '筋トレ',
  'ボイストレーニング',
  'ボディランゲージ',
  'クイズ',
  'グラフィックレコーディング',
  'ヨガ・ストレッチ',
  'コミュニケーション',
  '寿司握り',
  'スパイスカレー作り',
  'バヅクリエモ',
  '体験会',
]

export const PROGRAMS_SLUGS = {
  objective: OBJECTIVE,
  genre: GENRE,
}

export type ProgramCategoryListKeysName = {
  objectives: string
  genres: string
}

export const PROGRAM_CATEGORY_LIST_KEYS_NAME: ProgramCategoryListKeysName = {
  objectives: '目的から探す',
  genres: 'ジャンルから探す',
}

export const PROGRAM_CATEGORY_LIST: ProgramCategoryList = {
  objectives: [
    { name: '内定者フォロー', roman: 'follow_up_employee_candidate' },
    { name: '新人・若手社員研修', roman: 'training_new_employees' },
    { name: 'チームビルディング', roman: 'team_building' },
    { name: '社内イベント', roman: 'company_event' },
    { name: '労働組合レクリエーション', roman: 'trade_union_recreation' },
    { name: 'モチベーション向上', roman: 'motivation_up' },
    { name: 'テレワークスキルアップ', roman: 'telework_skill_up' },
    { name: 'コミュニケーション活性化', roman: 'communication_activity' },
    { name: '心理的安全性の醸成', roman: 'psychological_safety' },
    { name: 'エンゲージメント向上', roman: 'engagement_up' },
    { name: '離職防止', roman: 'turnover_prevention' },
    { name: 'メンタルヘルスケア', roman: 'mental_health_care' },
    { name: '研修', roman: 'training' },
  ],
  genres: [
    { name: 'プレゼンテーション', roman: 'presentation' },
    { name: 'おとなの図工', roman: 'arts_and_crafts_for_adults' },
    { name: 'マインドフルネス', roman: 'mindfulness' },
    { name: '筋トレ', roman: 'muscle_training' },
    { name: 'ヨガ・ピラティス', roman: 'yoga_and_pirates' },
    { name: 'クイズ', roman: 'quiz' },
    { name: '演劇', roman: 'theater' },
    { name: 'テレワークコミュニケーション', roman: 'telework_communication' },
    { name: '焚き火', roman: 'bonfire' },
    { name: '寿司握り', roman: 'making_sushi' },
    { name: '料理', roman: 'cooking' },
    { name: 'グラフィックレコーディング', roman: 'graphic_recording' },
    { name: 'バヅクリエモ', roman: 'emo' },
    { name: 'コミュニケーション', roman: 'communication' },
    { name: 'ボイストレーニング', roman: 'voice_training' },
    { name: 'オンライン運動会', roman: 'online_sports_day' },
    { name: '心理学', roman: 'psychology' },
    { name: '家事・育児', roman: 'housework' },
    { name: 'オンライン飲み会', roman: 'online_drinking' },
    { name: 'キャリアデザイン', roman: 'career_design' },
    { name: 'マネーリテラシー', roman: 'money_literacy' },
    { name: 'キャリアデザイン研修', roman: 'training_career_design' },
    { name: 'メンタルヘルス研修', roman: 'training_mental_health' },
    { name: 'ビジネススキル研修', roman: 'training_business_skill' },
    { name: 'ミニプログラム', roman: 'mini_program' },
    { name: 'ゲーム', roman: 'game' },
    { name: 'コミュニケーション研修', roman: 'training_communication_skill' },
    { name: 'ビジネスマインド研修', roman: 'training_business_mind' },
    { name: 'マネジメント研修', roman: 'training_management_skill' },
    { name: 'SDGS', roman: 'sdgs' },
    { name: 'サウナ・温泉', roman: 'sauna_and_spa' },
    { name: '映画・音楽', roman: 'cinema_and_music' },
    { name: 'ゴルフ', roman: 'golf' },
    { name: 'お酒', roman: 'alcohol' },
    { name: 'パソコンスキル', roman: 'pc_skill' },
    { name: 'エンゲージメント', roman: 'engagement' },
    { name: 'e-sports', roman: 'esports' },
  ],
}

export const PROGRAM_CATEGORIES = {
  objectives: [
    { name: '内定者フォロー' },
    { name: '新人・若手社員研修' },
    { name: 'チームビルディング' },
    { name: '社内イベント' },
    { name: '労働組合レクリエーション' },
    { name: 'モチベーション向上' },
    { name: 'テレワークスキルアップ' },
    { name: 'コミュニケーション活性化' },
    { name: '心理的安全性の醸成' },
    { name: 'エンゲージメント向上' },
    { name: '離職防止' },
    { name: 'メンタルヘルスケア' },
  ],
  genres: [
    { name: 'プレゼンテーション' },
    { name: 'おとなの図工' },
    { name: 'マインドフルネス' },
    { name: '筋トレ' },
    { name: 'メイク' },
    { name: 'ヨガ・ピラティス' },
    { name: '釣り' },
    { name: 'ボードゲーム' },
    { name: 'カート' },
    { name: '歴史探索' },
    { name: 'ドローン' },
    { name: 'お茶' },
    { name: 'テニス' },
    { name: 'キャンプ' },
    { name: 'BBQ' },
    { name: 'テスト' },
    { name: 'クイズ' },
    { name: 'ミニプログラム' },
    { name: '魔法' },
    { name: '演劇' },
    { name: 'テレワークコミュニケーション' },
    { name: '焚き火' },
    { name: '寿司握り' },
    { name: '料理' },
    { name: 'グラフィックレコーディング' },
    { name: 'バヅクリエモ' },
    { name: '体験会' },
    { name: 'コミュニケーション' },
    { name: 'ボディランゲージ' },
    { name: 'ボイストレーニング' },
    { name: 'イベント運営' },
    { name: 'オンライン運動会' },
    { name: '心理学' },
    { name: '家事・育児' },
    { name: 'オンライン飲み会' },
    { name: 'キャリアデザイン' },
    { name: 'マネーリテラシー' },
    { name: '営業研修' },
    { name: 'キャリアデザイン研修' },
    { name: 'メンタルヘルス研修' },
    { name: 'ビジネススキル研修' },
    { name: 'ゲーム' },
    { name: 'コミュニケーション研修' },
    { name: 'ビジネスマインド研修' },
    { name: 'マネジメント研修' },
    { name: 'SDGs' },
    { name: 'サウナ・温泉' },
    { name: '映画・音楽' },
    { name: 'ゴルフ' },
    { name: 'お酒' },
    { name: 'パソコンスキル' },
    { name: 'エンゲージメント' },
    { name: 'e-sports' },
  ],
}

export const MAX_DISPLAY_ITEMS_NUMBER = 10
