type Props = {
  text: string
  href: string
  target: string
}

export const BuzzkuriHeaderNavListItem = ({
  text,
  href,
  target,
}: Props): JSX.Element => (
  <li className="mr-[5px] text-[1vw] font-bold text-[#333] lg:hover:text-[#e6553a]">
    <a href={href} target={target}>
      {text}
    </a>
  </li>
)
