import { BrandBanner } from '@components/buzzkuri/shared/BrandBanner'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { Business } from '@type/buzzkuri/common'
import { cloudfrontPath } from '@utils/image'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { BuzzkuriSpMenuContent } from './MenuContent'
import { BuzzkuriSpMenuHeader } from './MenuHeader'

type Props = {
  business: Business
  defaultSwitcher?: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const BuzzkuriSpMenu = ({
  business,
  defaultSwitcher = true,
  setIsOpen,
}: Props): JSX.Element => {
  const [switchMenu, setSwitchMenu] = useState(defaultSwitcher)

  const closeAction = () => setIsOpen(false)
  return (
    <div className="flex flex-col items-center w-full min-h-screen bg-gradient-to-b from-primaryTo to-primaryFrom">
      <div className="flex flex-row justify-between mt-[5.1vw] w-[87.2vw]">
        <div className="pt-[1vw] w-[55vw]">
          <BrandBanner />
        </div>
        <button className="w-[6vw]" onClick={closeAction}>
          <ImageWraper
            src={`${cloudfrontPath}/programs/icons/top/cross_icon_bold.svg`}
            width={18}
            height={19}
            alt="閉じるアイコン"
          />
        </button>
      </div>
      <div className="mt-[9vw] mb-[6.4vw] easing-fade">
        <BuzzkuriSpMenuHeader {...{ switchMenu, setSwitchMenu }} />
      </div>
      <div className="pb-[7.3vw] easing-fade">
        <BuzzkuriSpMenuContent {...{ business, switchMenu }} />
      </div>
    </div>
  )
}
