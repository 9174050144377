import { useState, useRef, useCallback, useEffect } from 'react'

/**
 * スクロールの閾値
 * ページで一定のスクロール操作が行われた後に表示する要素のための閾値
 */
const SCROLL_THRESHOLD = 200

export const useCertainScrolling = (): { isShow: boolean } => {
  const [isShow, setIsShow] = useState(false)
  const isRunning = useRef(false)

  const isScrollToggle = useCallback(() => {
    if (isRunning.current) return
    isRunning.current = true
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    requestAnimationFrame(() => {
      if (scrollTop > SCROLL_THRESHOLD) {
        setIsShow(true)
      } else {
        setIsShow(false)
      }
      isRunning.current = false
    })
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', isScrollToggle, { passive: true })
    return () => {
      document.removeEventListener('scroll', isScrollToggle)
    }
  }, [isScrollToggle])

  return { isShow }
}
