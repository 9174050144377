import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
import { AuthContext, UserInfo } from './AuthContext'

type Props = {
  children: ReactNode
}

export function AuthProvider({ children }: Props): JSX.Element {
  const [user, setUser] = useState<UserInfo | null>(null)
  const router = useRouter()

  // useEffect(() => {
  //   // TODO: コードの共有化
  //   // TODO: エラーハンドリング
  //   const method = 'GET';
  //   const credentials = 'include';
  //   const mode = 'no-cors';
  //   const headers = {
  //     // 'Access-Control-Allow-Origin': '*',
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //   };
  //   const localhost =
  //     'https://localhost:3002/api/admin/attenders-lecturer/session';
  //   const preview =
  //     'https://preview.buzzkuri.com/api/admin/attenders-lecturer/session';
  //   fetch(preview, {
  //     method,
  //     // credentials,
  //     headers,
  //     // mode,
  //   })
  //     .then((res) => res.json())
  //     .then((data) => login(data))
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const login = (data: UserInfo) => {
    console.log('login success, name:', data.name)
    setUser(data)
    router.push('/admin/attenders-lecturer/my-page/decided-events-list')
  }

  const logout = () => {
    console.log('logout')
    setUser(null)
  }

  const value = {
    user,
    setUser,
    login,
    logout,
  }

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  )
}
