import '../styles/globals.css'
import 'tailwindcss/tailwind.css'
import * as Sentry from '@sentry/react'
import { AppProps } from 'next/dist/next-server/lib/router/router'
import { ContextApi } from '@context/index'
import { INTERNAL_SERVER_ERROR_PAGE_PROPS } from '@constants/common'
import React from 'react'
import { ErrorPage } from '@components/buzzkuri/shared/common/ErrorPage'
import { usePageview } from '@hook/gtm'

if (
  !process.env.NEXT_PUBLIC_PM2_ENV &&
  process.env.NEXT_PUBLIC_API_MOCKING === 'enabled'
) {
  require('../mocks')
}

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
  usePageview()
  return (
    <>
      <Sentry.ErrorBoundary
        fallback={<ErrorPage {...INTERNAL_SERVER_ERROR_PAGE_PROPS} />}
        showDialog
      >
        <ContextApi>
          <Component {...pageProps} />
        </ContextApi>
      </Sentry.ErrorBoundary>
    </>
  )
}

export default MyApp
