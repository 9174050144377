import { PreWraper } from '@components/shared/wraper/PreWraper'

type Props = {
  text: string
}

export const Heading = ({ text }: Props): JSX.Element => (
  <div className="lg:mt-[2.3vw]">
    <PreWraper
      classes="font-bold text-[5.3vw] leading-[9.1vw] lg:text-[1.9vw] lg:leading-[3.2vw] text-[#333333] text-center"
      {...{ text }}
    />
  </div>
)
