import { Business } from '@type/buzzkuri/common'
import { BrandBanner } from '../../BrandBanner'
import { BuzzkuriHeaderNav } from './Nav'

type Props = {
  business: Business
}

export const BuzzkuriHeader = ({ business }: Props): JSX.Element => {
  return (
    <header className="flex fixed top-0 left-0 z-[200] justify-between items-center px-[20px] lg:px-[2%] w-full h-[20.2vw] lg:h-[7.2vw] bg-white shadow-light lg:shadow-none">
      <div className="w-[36.5vw] lg:w-[15.7vw]">
        <BrandBanner />
      </div>
      <BuzzkuriHeaderNav {...{ business }} />
    </header>
  )
}
