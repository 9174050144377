import { DOCUMENT_DOWNLOAD_LINK_V2 } from '@constants/common'
import { useRouter } from 'next/router'

type Props = {
  reqRef: string
  documentDownloadButtonText?: string
  children: JSX.Element
}

export const DocumentDownloadAnchor = ({
  children,
  reqRef,
  documentDownloadButtonText,
}: Props): JSX.Element => {
  const router = useRouter()

  const currentUrlPath = () => {
    try {
      return decodeURI(router.asPath)
    } catch (e) {
      return 'error'
    }
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      data-document-download={`${reqRef}(path: ${router.asPath})`}
      data-programs-cv={documentDownloadButtonText}
      data-programs-path={currentUrlPath()}
      href={DOCUMENT_DOWNLOAD_LINK_V2}
    >
      {children}
    </a>
  )
}
