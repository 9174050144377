import { BuzzkuriBreadcrumb } from '../../uiParts/breadcrumb/BuzzkuriBreadcrumb'

type Props = {
  currentPathName: string
}

export const Breadcrumb = ({ currentPathName }: Props): JSX.Element => (
  <div className="mt-[3.2vw] lg:mt-[1.6vw] ml-[5.3vw] lg:ml-[6vw] w-[87.2vw] lg:w-[62.6vw] text-left">
    <BuzzkuriBreadcrumb {...{ currentPathName }} nonNominal />
  </div>
)
