import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { BREAKPOINTS } from '@constants/common'
import {
  ProgramsPaths,
  ProgramsPathsContext,
} from '@context/ProgramsPaths/Context'
import { WindowSizeContext } from '@context/WindowSize/Context'
import { cloudfrontPath } from '@utils/image'
import { useContext } from 'react'
import Link from 'next/link'

type Props = {
  navList: string[]
  updateKey: keyof ProgramsPaths
}

export const ProgramExtendedNavList = ({
  navList,
  updateKey,
}: Props): JSX.Element => {
  const winSize = useContext(WindowSizeContext)
  const { programsPaths, setProgramsPaths } = useContext(ProgramsPathsContext)

  const setState = (updateValue: string | undefined) => {
    setProgramsPaths((preState) => {
      preState = {
        objective: undefined,
        targetGroup: undefined,
        genre: undefined,
      }
      preState[updateKey] = updateValue
      return preState
    })
  }

  const unSelectAction = () => setState(undefined)

  return (
    <div className="flex flex-col mt-[3vw] lg:mt-[1.4vw] mb-[2.5vw] lg:mb-[1vw]">
      {programsPaths &&
        Object.values(navList).map((str, i) => (
          <div
            key={i}
            className={`flex justify-between items-center lg:hover:bg-[#ffffff66] h-[9vw] lg:h-[1.7vw]  ${
              programsPaths[updateKey] === str
                ? 'lg:bg-[#ffffff66] bg-[#f0b43066]'
                : ''
            }`}
          >
            <Link href={`/programs/${str}`}>
              <a className="flex justify-start pl-[3vw] lg:pl-[2.5vw] w-full text-[3.2vw] lg:text-[0.9vw] font-bold text-[#333333] lg:text-[#FAFAFA]">
                {str}
              </a>
            </Link>
            {programsPaths[updateKey] === str && (
              <button
                className="mr-[2.2vw] lg:mr-[0.6vw] w-[2.2vw] lg:w-[0.6vw]"
                onClick={unSelectAction}
              >
                <ImageWraper
                  src={
                    winSize?.width > BREAKPOINTS.laptop
                      ? `${cloudfrontPath}/programs/icons/programs/cross_icon.svg`
                      : `${cloudfrontPath}/programs/icons/programs/cross_icon_black.svg`
                  }
                  width={8.23}
                  height={8.23}
                  alt="バツのアイコン"
                />
              </button>
            )}
          </div>
        ))}
    </div>
  )
}
