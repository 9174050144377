import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { Business } from '@type/buzzkuri/common'
import { cloudfrontPath } from '@utils/image'

type Props = {
  business: Business
}

export const FooterToMyPage = ({ business }: Props): JSX.Element => {
  return (
    <div className="flex flex-col md:flex-row justify-between items-center mx-[7.1vw]">
      <div className="lg:mr-[13px] w-[61.3vw] lg:w-[22.5vw]">
        <ImageWraper
          src={`${cloudfrontPath}/images/top/buzzkuri-logo.png`}
          alt="バヅクリ"
          width={375}
          height={59}
        />
      </div>
      {!business?.id && (
        <div className="flex flex-col lg:flex-row lg:gap-x-[2.5vw] justify-center md:justify-end items-center mt-[8.5vw] lg:mt-0">
          <a
            className="flex justify-center items-center w-[61.3vw] lg:w-[18vw] h-[9vw] lg:h-[4vw] text-[3.46vw] lg:text-[1.6vw] font-bold text-[#EB8435] lg:hover:text-[#e5573c] rounded-[50px] border-2 border-[#EB8435] shadow-light lg:hover:opacity-70"
            href="/business/login"
          >
            ログイン
          </a>
          <a
            href="https://share.hsforms.com/1X8Xk3F-oTtmjeR5ltbuvIA4xn58"
            target="_blank"
            rel="noreferrer"
            className="flex justify-center items-center mt-[3.4vw] lg:mt-0 w-[61.3vw] lg:w-[21.9vw] h-[9vw] lg:h-[4vw] text-[3.46vw] lg:text-[1.4vw] font-bold text-white bg-gradient-to-r from-primaryTo to-primaryFrom rounded-[50px] border-[#EB8435] shadow-light border-1"
          >
            お問合せ・お見積り
          </a>
        </div>
      )}
    </div>
  )
}
