import { FC } from 'react'

export const HoverMenu: FC<Props> = ({ text, menuList }) => {
  return (
    <div className="flex relative">
      <button className="text-[1vw] font-bold text-[#333] cursor-pointer peer">
        {text}
      </button>
      <div
        className="hidden peer-hover:flex hover:flex
         top-[1.5vw]
         p-2.5
         py-5
         w-[16vw] absolute left-[-20px]
         flex-col bg-white"
      >
        {menuList.map(({ text, href, target }, index) => (
          <a
            className="mt-[20px] text-[.8vw] font-bold text-[#333] lg:hover:text-[#e6553a] hover:bg-gray-200"
            href={href}
            key={index}
            target={target}
          >
            {text}
          </a>
        ))}
      </div>
    </div>
  )
}

type Props = {
  text: string
  menuList: {
    text: string
    href: string
    target: string
  }[]
}
