import { createContext, useContext } from 'react'

const noop = () => {
  /** */
}

export interface BusinessUser {
  id: number
  name: string
}

export type BusinessUserContextType = {
  user: BusinessUser | null
  setUser: (user: BusinessUser) => void
  login: (data: BusinessUser) => void
  logout: () => void
}

const defaultValues: BusinessUserContextType = {
  user: null,
  setUser: noop,
  login: noop,
  logout: noop,
}

export const BusinessUserContext =
  createContext<BusinessUserContextType>(defaultValues)

export function useBusinessUser(): BusinessUserContextType {
  return useContext(BusinessUserContext)
}
