import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { Business } from '@type/buzzkuri/common'
import { cloudfrontPath } from '@utils/image'
import React, { useState, useEffect } from 'react'
import { BuzzkuriSpMenuContainer } from '../SpMenu/Contaner'

type Props = {
  business: Business
}

export const BuzzkuriHeaderMobileNav = ({ business }: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // sp menu でプロググラムカテゴリが選択された時にモーダルを非表示
    setIsOpen(false)
  }, [business])

  return (
    <>
      <button className="pt-[10px] w-[32px]" onClick={() => setIsOpen(!isOpen)}>
        <ImageWraper
          src={`${cloudfrontPath}/images/icon/hamburger.png`}
          alt="メニュー"
          width={64}
          height={56}
        />
      </button>
      <BuzzkuriSpMenuContainer {...{ isOpen, business, setIsOpen }} />
    </>
  )
}
