import { PROGRAMS_FV_IMAGE_VW_SIZE } from '@components/buzzkuri/pages/Programs/constant'
import { WindowSize } from '@context/WindowSize/Context'
import { BREAKPOINTS } from '../constants/common'
import { cloudfrontPath } from './image'

type Ext = 'png' | 'svg'

export const EXT: { [key in Ext]: Ext } = {
  png: 'png',
  svg: 'svg',
}

export const getImageSrc = (filePath: string): string =>
  `${cloudfrontPath}${filePath}`

export const getImagePath = (
  page: string,
  fileName: string,
  ext: Ext
): string => getImageSrc(`/programs/icons/${page}/${fileName}.${ext}`)

export const getImageRelativePath = (imageFullPath: string): string =>
  imageFullPath.slice(imageFullPath.indexOf('/image'), imageFullPath.length)

export const getScreenTarget = (width: number): 'pc' | 'sp' =>
  width >= BREAKPOINTS.tablet ? 'pc' : 'sp'

export const getResizePx = (
  winSize: WindowSize,
  imageSizeType: 'original' | 'thumbnail'
): string =>
  `${getResizeWidthPx(winSize.width, imageSizeType)}x${getResizeHeightPx(
    winSize.width,
    imageSizeType
  )}`

export const getResizeWidthPx = (
  width: number,
  imageSizeType: 'original' | 'thumbnail'
): number =>
  Math.round(
    width *
      0.01 *
      PROGRAMS_FV_IMAGE_VW_SIZE[getScreenTarget(width)][imageSizeType].widthRate
  )

export const getResizeHeightPx = (
  width: number,
  imageSizeType: 'original' | 'thumbnail'
): number =>
  Math.round(
    width *
      0.01 *
      PROGRAMS_FV_IMAGE_VW_SIZE[getScreenTarget(width)][imageSizeType]
        .heightRate
  )

// ex. '短時間で相互理解を深める！' => ['短時間で相互理解', 'を深める！　　　']
export const splitSpecifiedLength = (str: string, len: number): string[] => {
  const replace = `.{1,${len}}`
  const re = new RegExp(replace, 'g')
  const fullStrLen =
    (Math.floor(str.length / len) + (str.length % len > 0 ? 1 : 0)) * len
  return (str + '　'.repeat(fullStrLen)).slice(0, fullStrLen).match(re) ?? []
}
