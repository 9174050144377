import { ScreenTarget } from '@type/buzzkuri/home'
import { createContext, useContext } from 'react'

export type WindowSize = {
  width: number
  height: number
  screenTarget: ScreenTarget
}

export type WindowSizeContextType = WindowSize

const defaultValues: WindowSizeContextType = {
  width: 0,
  height: 0,
  screenTarget: 'pc',
}

export const WindowSizeContext =
  createContext<WindowSizeContextType>(defaultValues)

export function useBusinessUser(): WindowSizeContextType {
  return useContext(WindowSizeContext)
}
