export const PROGRAMS_FV_IMAGE_VW_SIZE = {
  pc: {
    original: {
      widthRate: 50.3125,
      heightRate: 24.609375,
    },
    thumbnail: {
      widthRate: 11.5625,
      heightRate: 5.625,
    },
  },
  sp: {
    original: {
      widthRate: 87.2,
      heightRate: 42.666666667,
    },
    thumbnail: {
      widthRate: 21.066666667,
      heightRate: 10.4,
    },
  },
}
