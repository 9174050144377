import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { cloudfrontPath } from '@utils/image'
import React, { FC, useState } from 'react'

export const ToggleMenu: FC<Props> = ({ menuList, text }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen((open) => !open)
  return (
    <>
      <div
        className="flex flex-row justify-between items-center mb-[2.7vw] h-[11.7vw] lg:hover:opacity-70 cursor-pointer easing-up__txt"
        onClick={toggleMenu}
      >
        <div className="text-[3.7vw] font-bold text-[#333]">{text}</div>
        <div className={`w-[7.5vw]`}>
          <ImageWraper
            src={`${cloudfrontPath}/programs/icons/top/ellipse_arrow_orange.svg`}
            alt="画面遷移アイコン"
            width={20}
            height={20}
            className={` ${isOpen ? 'rotate-[-90deg]' : 'rotate-180'}`}
          />
        </div>
      </div>
      {isOpen && (
        <ul className="mb-5 ml-2">
          {menuList.map(({ text, href, target }, index) => (
            <li
              key={index}
              className="mt-5 first:mt-0 text-[3.2vw] font-bold text-[#333] hover:text-[#e6553a]"
            >
              <a href={href} className="inline-block" target={target}>
                {text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}

type Props = {
  menuList: {
    text: string
    href: string
    target: string
  }[]
  text: string
}
