import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { DocumentDownloadAnchor } from '@components/ui/anchor'
import { CustomDataAttribute } from '@type/common'
import { cloudfrontPath } from '@utils/image'

type Props = {
  title: string
  customDataAttribute: CustomDataAttribute
  hasArrow?: boolean
  classes?: string
}

export const DocumentRequestBtn = ({
  title,
  customDataAttribute,
  classes,
  hasArrow,
}: Props): JSX.Element => {
  return (
    <DocumentDownloadAnchor
      reqRef={customDataAttribute.attributeValue}
      documentDownloadButtonText={title}
    >
      <div
        className={`flex justify-center items-center w-full h-full bg-gradient-to-r to-primaryFrom from-primaryTo lg:hover:opacity-50 lg:hover:cursor-pointer ${classes}`}
      >
        <div className="flex flex-row items-center text-white">
          <div className="font-bold">{title}</div>
          {hasArrow && (
            <div className="ml-[2.6vw] lg:ml-[1.2vw] w-[2vw] lg:w-[0.6vw]">
              <ImageWraper
                src={`${cloudfrontPath}/programs/icons/top/right_arrow.svg`}
                width={10}
                height={14}
              />
            </div>
          )}
        </div>
      </div>
    </DocumentDownloadAnchor>
  )
}
