import { MY_PAGE_USER_NAVIGATION } from '@components/buzzkuri/shared/constant'
import { Business } from '@type/buzzkuri/common'
import React from 'react'
import { LoggedInUser } from './LoggedInUser'
import { NonLoginUser } from './NonLoginUser'
import { MenuContentProgram } from './Program'

type Props = {
  business: Business
  switchMenu: boolean
}

export const BuzzkuriSpMenuContent = ({
  business,
  switchMenu,
}: Props): JSX.Element => (
  <div className="pt-[6.4vw] w-[87.5vw] h-full bg-white rounded-[20px]">
    {switchMenu ? (
      business?.id && business?.membershipServiceAvailable ? (
        <LoggedInUser
          navigation={MY_PAGE_USER_NAVIGATION}
          business={business}
        />
      ) : (
        <NonLoginUser />
      )
    ) : (
      <MenuContentProgram />
    )}
  </div>
)
