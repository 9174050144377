import { Business } from '@type/buzzkuri/common'
import React from 'react'
import { FooterNav } from './Nav'
import { FooterToMyPage } from './ToMyPage'

type Props = {
  business: Business
}

export const Footer = ({ business }: Props): JSX.Element => {
  return (
    <>
      <FooterToMyPage {...{ business }} />
      <div className="mt-[12vw] lg:mt-[3.9vw]">
        <FooterNav />
      </div>
      <div className="pt-[12.2vw] lg:pt-[8.5vw] pb-[4.2vw] lg:pb-[1vw] mx-[6vw] text-[3.2vw] lg:text-[0.9vw] text-center text-[#030300]">
        BUZZKURI inc. All Rights Reserved.
      </div>
    </>
  )
}
