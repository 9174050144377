import { MY_PAGE_USER_NAVIGATION } from '@components/buzzkuri/shared/constant'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { Business } from '@type/buzzkuri/common'
import { cloudfrontPath } from '@utils/image'
import Link from 'next/link'
import React from 'react'
import { LoggedInUserInfo } from './BusinessUserInfo'
import { NavigationList } from './NavigationList'
import { LogoutNavItem } from './NavigationList/logout'

type Props = {
  navigation: typeof MY_PAGE_USER_NAVIGATION
  business: Business
}

export const LoggedInUser = ({ navigation, business }: Props): JSX.Element => (
  <div className="flex flex-col px-[9vw]">
    <Link href="/programs" passHref>
      <div className="flex justify-center items-center w-full h-[14vw] bg-gradient-to-r from-primaryTo to-primaryFrom rounded-[11px] shadow-button lg:hover:opacity-70">
        <div className="flex flex-row justify-center items-center w-full easing-up__request">
          <div className="text-[4.5vw] font-bold tracking-[0.4vw] text-white">
            プログラムリクエスト
          </div>
          <div className="ml-[2.5vw] w-[2.1vw]">
            <ImageWraper
              src={`${cloudfrontPath}/programs/icons/top/right_arrow_bold.svg`}
              alt="右向き矢印のアイコン"
              width={23}
              height={31}
            />
          </div>
        </div>
      </div>
    </Link>

    <div className="mt-[5.4vw]">
      <div className="pb-[8vw]">
        <LoggedInUserInfo {...business} />
        <div className="mt-[2.4vw] h-[1px] border-b border-[#999999]" />
        <div className="mt-[5.5vw]">
          <a
            className="flex flex-row items-center"
            href={`/business/memberships/users/${business.userId}`}
          >
            <div className="w-[10.7vw]">
              <ImageWraper
                className="rounded-[50%]"
                src={`${cloudfrontPath}/images/icon/profile-icon_empty.jpg`}
                alt="ユーザーアイコン"
                width={180}
                height={180}
              />
            </div>
            <div className="ml-[2.7vw]">
              <div className="text-[4.3vw] font-bold text-[#333]">
                マイページ
              </div>
            </div>
          </a>
        </div>

        <div className="mt-[5vw]">
          <NavigationList
            {...navigation.map(({ text, path }) => ({
              text,
              path:
                typeof path === 'string'
                  ? path
                  : text === 'リクエスト中のイベント'
                  ? path(business.id)
                  : path(business.id),
            }))}
          />
          <LogoutNavItem />
        </div>
      </div>
    </div>
  </div>
)
