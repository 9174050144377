import React, { Dispatch, SetStateAction } from 'react'
import { BuzzkuriSpMenuHeaderItem } from './item'

type Props = {
  switchMenu: boolean
  setSwitchMenu: Dispatch<SetStateAction<boolean>>
}

export const BuzzkuriSpMenuHeader = ({
  switchMenu,
  setSwitchMenu,
}: Props): JSX.Element => {
  return (
    <div className="flex flex-row justify-around items-center w-[87.2vw] h-[14.4vw] bg-white rounded-[5px]">
      <button onClick={() => setSwitchMenu((bool) => !bool)}>
        <BuzzkuriSpMenuHeaderItem text="メニュー" isSelected={switchMenu} />
      </button>
      <button onClick={() => setSwitchMenu((bool) => !bool)}>
        <BuzzkuriSpMenuHeaderItem text="プログラム" isSelected={!switchMenu} />
      </button>
    </div>
  )
}
