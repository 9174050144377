type Props = {
  text: string
  path: string
}

export const NavigationListItem = ({ text, path }: Props): JSX.Element => (
  <a
    className="flex justify-start items-center h-[10vw] text-[4.3vw] text-[#333333] bg-white lg:hover:opacity-70 easing-up__txt"
    href={path}
  >
    {text}
  </a>
)
