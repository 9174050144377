type Props = {
  text: string
}

export const Description = ({ text }: Props): JSX.Element => (
  <div className="mt-[4vw] lg:mt-[2vw] w-[88vw] lg:w-auto text-center">
    <div className="text-[3.7vw] lg:text-[1.3vw] leading-[5.9vw] lg:leading-normal text-[#333333]">
      {text}
    </div>
  </div>
)
