import { useState, useEffect } from 'react'

// type
export type BodySize = {
  width: number
  height: number
}

// Hook
export const useBodySize = (): BodySize => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [bodySize, setBodySize] = useState<BodySize>({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    // const bodyElm = document.;
    // Handler to call on window resize
    function handleResize() {
      // console.log('bodyElm.clientWidth', window.innerWidth);
      // Set window width/height to state
      setBodySize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return bodySize
}
