import Link from 'next/link'

export const ToTopBtn = (): JSX.Element => (
  <div className="mt-[6vw] lg:mt-[3.1vw]">
    <Link href="/">
      <a>
        <div className="flex justify-center items-center w-[36.8vw] lg:w-[10.8vw] h-[8vw] lg:h-[2.3vw] rounded-[30px] border border-[#EB8435] lg:hover:opacity-50">
          <div className="text-[3.7vw] lg:text-[1.1vw] text-[#EB8435]">
            サイトトップへ
          </div>
        </div>
      </a>
    </Link>
  </div>
)
