import React, { Dispatch, SetStateAction } from 'react'
import { ProgramExtendedHeaderItem } from './Item'

type Props = {
  headerItem: {
    text: string
    isOpen: boolean
    idx: number
  }
  setState: Dispatch<SetStateAction<boolean[]>>
}

export const ProgramExtendedHeader = ({
  headerItem,
  setState,
}: Props): JSX.Element => {
  const getNextState = (headerItemsIdx: number) => {
    setState((preState) =>
      preState.map((bool, i) => (i === headerItemsIdx ? !bool : bool))
    )
  }

  return (
    <>
      <div className={headerItem.isOpen ? '' : 'mb-[4.4vw] lg:mb-[2.2vw]'}>
        <button
          className="w-full lg:hover:opacity-70"
          onClick={() => getNextState(headerItem.idx)}
        >
          <ProgramExtendedHeaderItem {...headerItem} />
        </button>
      </div>
    </>
  )
}
