import { DocumentRequestBtn } from '@components/buzzkuri/shared/DocumentRequestBtn'
import { getDomain } from '@utils/http'

export const InformationRequestsContent = (): JSX.Element => {
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-row justify-center items-center">
        <div className="inline-block w-[20px] h-[20px] border-r-[3px] border-red transform rotate-[135deg]" />
        <div className="pt-[13px] text-[3.6vw] lg:text-[16px] font-bold">
          30秒で簡単！資料請求
        </div>
        <div className="inline-block w-[20px] h-[20px] border-l-[3px] border-red transform rotate-[-135deg]" />
      </div>

      <div className="mt-[10px] w-[80vw] lg:w-[260px] h-[11vw] lg:h-[50px] lg:text-[16px] font-bold">
        <DocumentRequestBtn
          title="資料を無料ダウンロード"
          customDataAttribute={{
            attributeName: 'data-bottom-documentdownload',
            attributeValue: `https://${getDomain}/`,
          }}
          classes="rounded-[50px]"
        />
      </div>
    </div>
  )
}
