import { Dispatch } from 'react'
import { SetStateAction } from 'react'
import { createContext, useContext } from 'react'

export interface UserInfo {
  id: number
  name: string
  adminRole: 'lecturer' | 'attender'
}

export type authContextType = {
  user: UserInfo | null
  setUser: Dispatch<SetStateAction<UserInfo | null>>
  login: (data: UserInfo) => void
  logout: () => void
}

const noop = () => {
  /** */
}

const authContextDefaultValues: authContextType = {
  user: null,
  setUser: noop,
  login: noop,
  logout: noop,
}

export const AuthContext = createContext<authContextType>(
  authContextDefaultValues
)

export function useAuth(): authContextType {
  return useContext(AuthContext)
}
