import { AllPageProps } from '@type/buzzkuri/api'
import { ErrorStatus } from '@type/common'
import { GetServerSidePropsContext } from 'next'
import router from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import * as Sentry from '@sentry/nextjs'
import { jaToRoman } from '@components/buzzkuri/pages/ProgramsSlugs/functions'
import { MAX_DISPLAY_ITEMS_NUMBER } from '@constants/buzzkuri/programs'
import {
  ProgramCategoryList,
  ProgramsSlugsPageRes,
  Slugs,
} from '@type/buzzkuri/programs-categor'

type HttpReqMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH'

export const BESE_REQUEST_OPTIONS: RequestInit = {
  credentials: 'include',
  headers: {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
}

export const getRequestOptions = (
  cookie?: string,
  httpMethod?: HttpReqMethod
): RequestInit => {
  const options = { ...BESE_REQUEST_OPTIONS }
  if (cookie) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: TODO: きちんと調べた上対応した方がいいと思うので、一旦通す
    options.headers['cookie'] = cookie
  }
  if (httpMethod) {
    options.method = httpMethod
  }
  return options
}

const env = process.env.PM2_ENV || process.env.NEXT_PUBLIC_PM2_ENV
export const getDomain = !env
  ? 'buzzkuri.local'
  : `${env === 'production' ? 'buzzkuri.com' : `${env}-buzz.click`}`

const genBasic = (env: 'staging' | 'preview') =>
  `${process.env.BASIC_AUTH_NAME}:${process.env.BASIC_AUTH_PASS}@${env}-buzz.click`

const getOrigin = () => {
  const domain = getDomain

  if (domain === 'staging-buzz.click') {
    return genBasic('staging')
  } else if (domain === 'preview-buzz.click') {
    return genBasic('preview')
  } else {
    return domain
  }
}

export const origin = `https://${getOrigin()}`

const errorTranstionAction = (e: unknown, action: string) => {
  router
    .push('/500')
    .then(() => {
      Sentry.captureException({
        error: e,
        action,
        isTtransitionCompleteTo500page: true,
      })
    })
    .catch((error) => {
      Sentry.captureException({
        error: error,
        action,
        isTransitionCompleteTo500page: false,
      })
    })
}

export const logout = (): void => {
  fetch('/api/businesses/logout', {
    method: 'DELETE',
    ...getRequestOptions(),
  })
    .then(async (res) => {
      // 302: ログアウト完了
      const data = await res.json()
      if (res.status === 302) {
        if (data.url) {
          // オペレーターモードログイン中からのログアウトの場合
          window.location.href = data.url
        } else {
          router.push('/')
        }
      } else {
        // 正常系（想定していないレスポンス）
        router.push(
          `/error?httpStatus=${res.status}&message=${data.error.message}`
        )
        Sentry.captureException({
          responseStatus: res.status,
          action: 'logout',
          isTransitionCompleteTo500page: false,
        })
      }
    })
    .catch((e) => errorTranstionAction(e, 'logout'))
}

export const getPagePropsReq = async (
  context: GetServerSidePropsContext<ParsedUrlQuery>,
  endpoint: string,
  title?: string,
  isAllow404?: boolean,
  httpMethod?: HttpReqMethod
): Promise<{
  props: AllPageProps | ErrorStatus
}> => {
  try {
    const fullEndpoint = `${origin}${endpoint}`
    const cookie = context.req.headers.cookie
    const res = await fetch(fullEndpoint, getRequestOptions(cookie, httpMethod))
    const props = (await res.json()) as AllPageProps
    if (res.status >= 400) {
      if (!(isAllow404 && res.status === 404)) {
        Sentry.captureException({
          props: res,
          resStatus: res.status,
          requestEndpoint: fullEndpoint,
        })
      }
      context.res.statusCode = res.status
      return { props: { errorStatus: res.status } }
    }
    if (title) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TODO: きちんと調べた上対応した方がいいと思うので、一旦通す
      props.pageContent['title'] = title
    }
    return { props }
  } catch (e) {
    const httpStatus = getResponseStatus(e) ?? 500
    context.res.statusCode = httpStatus
    Sentry.captureException({
      error: e,
      requestEndpoint: endpoint,
    })
    return { props: { errorStatus: httpStatus } }
  }
}

export const programPdfdownloadReq = (
  programId: number,
  programName: string
): void => {
  document.body.classList.add('cursor-wait')
  fetch(`/api/programs/${programId}/pdf`, {
    credentials: 'include',
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.dataset.dataPdfDownload = programName
      a.download = `${programName}.pdf`
      a.href = url
      a.click()
      a.remove()
      setTimeout(() => {
        URL.revokeObjectURL(url)
      }, 1e4)
      document.body.classList.remove('cursor-wait')
    })
    .catch((e) => errorTranstionAction(e, 'program pdf download'))
}

export const getProgramCategoryEndpoint = async (
  context: GetServerSidePropsContext<ParsedUrlQuery>,
  slugs: string[],
  page: string
): Promise<
  | {
      apiEndpoint: string
      programCategoryList: ProgramCategoryList
    }
  | { props: ErrorStatus }
> => {
  const cookie = context.req.headers.cookie
  const res = await fetch(
    `${origin}/api/programs/category_list`,
    getRequestOptions(cookie)
  )
  const programCategoryList = await res.json()
  const romanProgramCategory = jaToRoman(slugs, programCategoryList)
  // slugs pram に想定外のものが一つでも来たら 404を返す
  if (romanProgramCategory === '') {
    context.res.statusCode = 404
    return { props: { errorStatus: 404 } }
  }

  const apiEndpoint =
    slugs.length === 1 && Number(slugs[0]) > 0
      ? `/api/programs/${slugs[0]}`
      : `/api/programs/${romanProgramCategory}?page=${
          page ? page : 1
        }&maxDisplayItemsNumber=${MAX_DISPLAY_ITEMS_NUMBER}`
  return { apiEndpoint, programCategoryList }
}

export const getProgramsSlugsPagePropsReq = async (
  context: GetServerSidePropsContext<ParsedUrlQuery>
): Promise<
  | {
      props: {
        slugs: Slugs
        props: ProgramsSlugsPageRes | ErrorStatus
        programCategoryList: ProgramCategoryList
      }
    }
  | { props: { props: ErrorStatus } }
> => {
  try {
    const slugs = Array.isArray(context.query.slugs) ? context.query.slugs : []
    const page =
      typeof context.query.page === 'string' ? context.query.page : ''

    const res = await getProgramCategoryEndpoint(context, slugs, page)
    if ('props' in res && res.props.errorStatus) {
      return { props: res } as { props: { props: ErrorStatus } }
    }
    const { apiEndpoint, programCategoryList } = res as {
      apiEndpoint: string
      programCategoryList: ProgramCategoryList
    }
    const props = (await getPagePropsReq(
      context,
      apiEndpoint,
      '',
      slugs.length === 1 && Number(slugs[0]) > 0 ? true : false
    )) as {
      props: ProgramsSlugsPageRes | ErrorStatus
    }

    return {
      props: {
        slugs,
        ...props,
        programCategoryList,
      },
    }
  } catch (e) {
    const httpStatus = getResponseStatus(e) ?? 500
    context.res.statusCode = httpStatus
    Sentry.captureException({
      error: e,
    })
    return { props: { props: { errorStatus: httpStatus } } }
  }
}

const getResponseStatus = (e: unknown): number | null => {
  if (e === null || typeof e === 'object') return null
  const error = e as { response: Response | null }
  return error.response?.status ?? null
}
