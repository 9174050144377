import { DocumentDownloadAnchor } from '@components/ui/anchor'
import { HEADER_NAV_ITEMS } from '@utils/constants'
import React from 'react'
import { NonLoginUserNavList } from './NavigationList'
import { ToggleMenu } from './ToggleMenu'

export const NonLoginUser = (): JSX.Element => (
  <div className="flex flex-col mb-[7.8vw] h-full">
    <div className="px-[9vw]">
      {Object.entries(HEADER_NAV_ITEMS).map(([key, { text, href, target }]) =>
        key === 'service' ? (
          <ToggleMenu
            menuList={HEADER_NAV_ITEMS['service'].subNav}
            text={text}
            key={key}
          />
        ) : key === 'about' ? (
          <ToggleMenu
            menuList={HEADER_NAV_ITEMS['about'].subNav}
            text={text}
            key={key}
          />
        ) : (
          <div className="mb-[2.7vw] last:mb-0" key={key}>
            <NonLoginUserNavList {...{ href, target }}>
              {text}
            </NonLoginUserNavList>
          </div>
        )
      )}
    </div>
    <div className="flex justify-center mt-[10.7vw] mb-[12vw]">
      <DocumentDownloadAnchor reqRef="スマホハンバーガーメニュー">
        <div className="flex justify-center items-center w-[69.4vw] h-[14vw] text-[4.5vw] font-bold tracking-[0.4vw] text-white bg-gradient-to-r from-primaryTo to-primaryFrom rounded-[11px] shadow-button lg:hover:opacity-70">
          <div className="easing-up__request">無料資料ダウンロード</div>
        </div>
      </DocumentDownloadAnchor>
    </div>
  </div>
)
