import { ModalWaper } from '@components/shared/wraper/ModalWaper'
import { Business } from '@type/buzzkuri/common'
import React, { Dispatch, SetStateAction } from 'react'
import { BuzzkuriSpMenu } from '..'

const customStyles = {
  content: {
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'transparent',
    top: 0,
    left: 0,
    width: '100%',
    // marginTop: '16.5vw',
    zIndex: 9999999,
    overflow: 'auto',
  },
}

type Props = {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  business: Business
}

export const BuzzkuriSpMenuContainer = ({
  isOpen,
  business,
  setIsOpen,
}: Props): JSX.Element => {
  return (
    <ModalWaper
      {...{ isOpen }}
      {...{ customStyles }}
      contentLabel="mobile navigation menu"
      closeTimeoutMS={500}
      portalClassName="fedeIn-modal"
    >
      <BuzzkuriSpMenu {...{ business, setIsOpen }} />
    </ModalWaper>
  )
}
