import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { BREAKPOINTS } from '@constants/common'
import { WindowSizeContext } from '@context/WindowSize/Context'
import { cloudfrontPath } from '@utils/image'
import React, { useContext } from 'react'

type Props = {
  text: string
  isOpen: boolean
}

export const ProgramExtendedHeaderItem = ({
  text,
  isOpen,
}: Props): JSX.Element => {
  const winSize = useContext(WindowSizeContext)
  return (
    <div className="flex flex-row lg:flex-row-reverse justify-between lg:justify-end items-center w-full">
      <div className="ml-0 lg:ml-[0.7vw]">
        <div className="text-[4.8vw] lg:text-[1.4vw] font-bold text-[#333333] lg:text-[#FAFAFA]">
          {text}
        </div>
      </div>
      <div
        className={
          isOpen ? 'rotate-90 lg:rotate-0' : '-rotate-90 lg:rotate-180'
        }
      >
        <div className="w-[7.5vw] lg:w-[1.6vw]">
          {winSize?.screenTarget && (
            <ImageWraper
              src={
                winSize?.width > BREAKPOINTS.laptop
                  ? `${cloudfrontPath}/programs/icons/top/ellipse_arrow.svg`
                  : `${cloudfrontPath}/programs/icons/top/ellipse_arrow_orange.svg`
              }
              alt="子メニュー表示切り替えアイコン"
              width={20}
              height={20}
            />
          )}
        </div>
      </div>
    </div>
  )
}
