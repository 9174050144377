import React from 'react'
import { AuthProvider } from './AuthProvider'
import { BusinessUserProvider } from './BusinessUserProvider'
import { ProgramNavListProvider } from './ProgramNavList/Provider'
import { ProgramsPathsProvider } from './ProgramsPaths/Provider'
import { WindowSizeProvider } from './WindowSize/Provider'

type Props = {
  children: JSX.Element
}

export const ContextApi = ({ children }: Props): JSX.Element => (
  <>
    <BusinessUserProvider>
      <AuthProvider>
        <WindowSizeProvider>
          <ProgramNavListProvider>
            <ProgramsPathsProvider>{children}</ProgramsPathsProvider>
          </ProgramNavListProvider>
        </WindowSizeProvider>
      </AuthProvider>
    </BusinessUserProvider>
  </>
)
