import Image from 'next/image'
import { ConvertImage } from './ConvertImage'
import { encodeSvg } from './functions'
import { memo } from 'react'
import { Img } from '@type/common'

type Props = Img & {
  className?: string
}

export const ImageWraper = ({
  className,
  src,
  alt = '',
  width,
  height,
  priority = false,
  objectFit = undefined,
  layout = 'responsive',
}: Props): JSX.Element => {
  return (
    <Image
      {...{ className, src, alt, width, height }}
      quality="100"
      layout={layout}
      placeholder="blur"
      blurDataURL={encodeSvg(
        ConvertImage({
          width,
          height,
        })
      )}
      priority={priority}
      {...{ objectFit }}
    />
  )
}

export const MemoizedImageWraper = memo(ImageWraper)
