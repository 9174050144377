import React from 'react'
import { NavigationListItem } from './item'

type Props = {
  text: string
  path: string
}[]

export const NavigationList = (props: Props): JSX.Element => (
  <div>
    {Object.values(props).map((item, i) => (
      <div key={i} className="mt-[4vw] first:mt-0 font-bold">
        <NavigationListItem {...item} />
      </div>
    ))}
  </div>
)
