import { BreadcrumbItem } from '@type/common'
import { removePrams } from '@utils/url'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

type Props = {
  currentPathName?: string
  nonNominal?: boolean
}

type RenamePath = {
  '': string
  programs: string
  popular: string
  new_arrival: string
  about: string
  'team-building': string
  prices: string
}

const RENAME_PATH: RenamePath = {
  '': 'TOP',
  programs: 'プログラム(対面/オンライン)一覧',
  popular: '人気のプログラム(対面/オンライン)',
  new_arrival: '新着プログラム(対面/オンライン)',
  about: 'バヅクリとは',
  'team-building': 'チームビルディングとは',
  prices: '料金プラン',
}

const isPathKey = (str: string): str is keyof RenamePath => str in RENAME_PATH

const getRenamePath = (str: string): string => {
  const path = removePrams(str)
  return isPathKey(path) ? RENAME_PATH[path] : path
}

export const useBreadcrumbItems = ({
  currentPathName,
  nonNominal,
}: Props): BreadcrumbItem[] => {
  const router = useRouter()
  const breadcrumbItems = useMemo(() => {
    // router.asPath case
    // 1. /
    // 2. /programs
    // 3. /programs/[popular or new_arrival or [programId]]
    // 4. /programs/[目的]/[対象者]/[ジャンル]

    const decodePaths = decodeURI(router.asPath).split('/')
    const decodePathsLen = decodePaths.length

    // 異常系
    const displayTextArr: string[] = []
    const sliceIdx = nonNominal ? 2 : 3
    decodePaths.slice(0, sliceIdx).forEach((v, i) => {
      if (i < sliceIdx - 1) {
        displayTextArr.push(getRenamePath(v))
      } else if (decodePathsLen >= sliceIdx) {
        displayTextArr.push(
          currentPathName ? currentPathName : getRenamePath(v)
        )
      }
    })
    const breadcrumbItems = displayTextArr.map((v, i) => ({
      description: v,
      url: decodePaths.slice(0, i + 1).join('/')
        ? decodePaths.slice(0, i + 1).join('/')
        : '/',
    }))
    return breadcrumbItems
  }, [currentPathName, nonNominal, router.asPath])

  return breadcrumbItems
}
