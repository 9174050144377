const env = process.env.NEXT_PUBLIC_PM2_ENV
const production = 'd1v6fx8fk7eiec'
const other = 'd2c9x2x0n5546t'

export const cloudfrontPath = `https://${
  env === 'production' ? production : other
}.cloudfront.net`

export const imgPath = `https://images.${
  env === 'production' ? 'buzzkuri.com' : 'staging-buzz.click'
}`

export const createCustomImgPath = (
  type: 'resize' | 'crop',
  width: number,
  height: number,
  path: string
) => {
  return `${imgPath}/${type}/${width}x${height}/${path}`
}
