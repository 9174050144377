type Props = {
  width: number
  height: number
}

export const ConvertImage = (props: Props): JSX.Element => (
  <svg
    {...props}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <linearGradient id="g">
        <stop stopColor="#333" offset="20%" />
        <stop stopColor="#222" offset="50%" />
        <stop stopColor="#333" offset="70%" />
      </linearGradient>
    </defs>
    <rect {...props} fill="#333" />
    <rect id="r" {...props} fill="url(#g)" />
    <animate
      xlinkHref="#r"
      attributeName="x"
      from={-props.width}
      to={props.width}
      dur="1s"
      repeatCount="indefinite"
    />
  </svg>
)
