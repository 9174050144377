import { Business } from '@type/buzzkuri/common'
import { useBodySize } from '@hook/windowSize'
import { BREAKPOINTS } from '@constants/common'
import { BuzzkuriHeaderPcNav } from './PcNav'
import { BuzzkuriHeaderMobileNav } from './MobileNav'

type Props = {
  business: Business
}

export const BuzzkuriHeaderNav = ({ business }: Props): JSX.Element => {
  const winSize = useBodySize()

  return winSize?.width > BREAKPOINTS.laptop ? (
    <BuzzkuriHeaderPcNav {...{ business }} />
  ) : (
    <BuzzkuriHeaderMobileNav {...{ business }} />
  )
}
