import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { useCertainScrolling } from '@hook/useCertainScrolling'
import { getImageSrc } from '@utils/functions'
import React, { useState } from 'react'
import { InformationRequestsContent } from '../Content'

export const MiniOfferPopup: React.FC = (): JSX.Element => {
  const { isShow } = useCertainScrolling()
  const [isScaleDown, setScaleDown] = useState(false)

  const close = () => {
    setScaleDown(true)
  }

  const open = () => {
    setScaleDown(false)
  }

  return (
    <>
      {isShow && !isScaleDown && (
        <div className="fixed right-0 bottom-0 z-40 w-[400px] h-[130px] bg-white rounded-l-[20px] border-[3px] border-[#EB8435] shadow-button animate-fade-in">
          <div className="flex flex-row w-full h-full">
            <button
              className="mt-[10px] ml-[10px] w-[24px] h-[24px] hover:opacity-80"
              onClick={close}
            >
              <ImageWraper
                src={getImageSrc('/images/top/free-close.png')}
                alt="close icon"
                width={48}
                height={48}
              />
            </button>
            <div className="flex relative items-center w-full h-full text-xs font-bold">
              <div className="ml-[36px]">
                <InformationRequestsContent />
              </div>
              <div className="absolute right-[-3px] bottom-[-3px] z-50 w-[106px]">
                <ImageWraper
                  src={getImageSrc('/images/top/guide.svg')}
                  alt="close icon"
                  width={106}
                  height={76}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {isShow && isScaleDown && (
        <button onClick={open}>
          <div className="fixed right-[-2vw] bottom-[-2vw] w-[10vw] rounded-l-[20px]">
            <ImageWraper
              src={getImageSrc('/images/top/guide-close_dl@2x.png')}
              alt="資料を無料ダウンロード"
              width={320}
              height={380}
            />
          </div>
        </button>
      )}
    </>
  )
}
