import { cloudfrontPath } from '@utils/image'
import Link from 'next/link'
import React from 'react'

export const BrandBanner = (): JSX.Element => {
  return (
    <Link href="/">
      <div className="flex flex-col cursor-pointer">
        <span className="lg:hidden font-bold text-[2.6vw] mb-[1.3vw]">
          エンゲージメント向上なら
        </span>
        <div className="w-[36.5vw] mr-[5px] lg:mr-[13px] lg:w-[15.7vw]">
          <img
            src={`${cloudfrontPath}/images/top/buzzkuri-logo.png`}
            alt="バヅクリ"
          />
        </div>
      </div>
    </Link>
  )
}
