import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { Business } from '@type/buzzkuri/common'
import { cloudfrontPath } from '@utils/image'
import { useMemo } from 'react'

type Props = Business

export const LoggedInUserInfo = ({
  userName,
  name,
  remainingTicketNumber,
  totalTicketNumber,
  planEffectiveDate, //ex: '2021年10月22日'
  surveyServiceStatus,
  userSurveyRole,
  surveyAdminHomeUrl,
}: Props): JSX.Element => {
  const isSurveyNavLinkVisible = useMemo(
    () =>
      surveyServiceStatus &&
      (userSurveyRole === 'admin' || userSurveyRole === 'owner'),
    [surveyServiceStatus, userSurveyRole]
  )
  return (
    <div>
      <div className="text-[3.7vw] text-[#333]">{name}</div>
      <div className="mt-[1.3vw] text-[5.3vw] font-bold text-[#333]">
        {userName}さん
      </div>

      {isSurveyNavLinkVisible && (
        <div className="mt-[1vw]">
          <a href={surveyAdminHomeUrl} className="flex flex-row items-center">
            <span className="text-[3.5vw] text-[#aaaaaa]">
              エンゲージメントサーベイへ切り替え
            </span>
            <div className="w-[5vw] h-[5vw]">
              <ImageWraper
                src={`${cloudfrontPath}/images/icon/arrow-square-out.png`}
                alt=""
                width={24}
                height={24}
              />
            </div>
          </a>
        </div>
      )}
      <div className="mt-[5vw]">
        <div className="text-[3.7vw] text-[#333]">
          サービス利用可能回数:{' '}
          <span className="font-bold">
            {`${remainingTicketNumber}/${totalTicketNumber}`}
          </span>
        </div>
        <div className="mt-[1.6vw]">
          <div className="text-[3.7vw] text-[#333]">
            サービス利用期限:{' '}
            <span className="font-bold">{planEffectiveDate}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
