import { useRouter } from 'next/router'
import { ReactNode, useState } from 'react'
import { BusinessUser, BusinessUserContext } from './BusinessUserContext'

type Props = {
  children: ReactNode
}

export function BusinessUserProvider({ children }: Props): JSX.Element {
  const [user, setUser] = useState<BusinessUser | null>(null)
  const router = useRouter()

  const login = (data: BusinessUser) => {
    console.log('login success, name:', data.name)
    setUser(data)
    router.push('/admin/attenders-lecturer/my-page/decided-events-list')
  }

  const logout = () => {
    console.log('logout')
    setUser(null)
  }

  const value = {
    user,
    setUser,
    login,
    logout,
  }

  return (
    <>
      <BusinessUserContext.Provider value={value}>
        {children}
      </BusinessUserContext.Provider>
    </>
  )
}
