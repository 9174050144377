export const BUSINESS_USER_PAGE = '/business/memberships/users'

export const FOOTER_LEFT_NAV_ITEMS = [
  {
    category: 'バヅクリについて',
    navItems: [
      {
        text: 'バヅクリとは',
        href: '/about',
        target: '_self',
      },
      {
        text: '人的資本とエンゲージメント',
        href: '/human_capital_engagement',
        target: '_self',
      },
    ],
  },
  {
    category: 'サービス',
    navItems: [
      {
        text: 'エンゲージメント改善',
        href: 'https://hr.buzzkuri.com/engagement',
        target: '_blank',
      },
      {
        text: '内定者フォロー',
        href: 'https://tb.buzzkuri.com/naiteisha/',
        target: '_blank',
      },
      {
        text: '社内イベント 組合レク',
        href: 'https://tb.buzzkuri.com/event/',
        target: '_blank',
      },
      {
        text: '研修',
        href: 'https://hr.buzzkuri.com/training',
        target: '_blank',
      },
    ],
    subNabItems: [
      {
        text: 'プログラム一覧',
        href: '/programs',
        target: '_self',
      },
      {
        text: '講師一覧',
        href: '/lecturers',
        target: '_self',
      },
      {
        text: '導入事例',
        href: '/cases',
        target: '_self',
      },
      {
        text: '料金プラン',
        href: '/prices',
        target: '_self',
      },
      {
        text: 'よくある質問',
        href: '/questions',
        target: '_self',
      },
    ],
  },
  {
    category: 'メディア・イベント',
    navItems: [
      {
        text: 'バヅクリ HR研究所',
        href: 'https://buzzkuri.com/columns/',
        target: '_blank',
      },
      {
        text: '資料ダウンロード',
        href: 'https://download.buzzkuri.com/document',
        target: '_blank',
      },
      {
        text: 'お役立ち資料',
        href: 'https://download.buzzkuri.com/whitepaper',
        target: '_blank',
      },
      {
        text: 'セミナー・無料体験会',
        href: 'https://download.buzzkuri.com/engagement_portal',
        target: '_blank',
      },
    ],
  },
  {
    category: 'その他',
    navItems: [
      {
        text: '運営会社',
        href: 'https://buzzkuri.co.jp/',
        target: '_blank',
      },
      {
        text: '会員権サービス利用規約',
        href: '/business-term',
        target: '_self',
      },
      {
        text: 'エンゲージメントサーベイ利用規約',
        href: '/survey-term',
        target: '_self',
      },
      {
        text: 'プライバシーポリシー',
        href: 'https://buzzkuri.co.jp/privacy',
        target: '_self',
      },
      {
        text: '販売パートナー募集',
        href: 'https://download.buzzkuri.com/salespartner',
        target: '_blank',
      },
    ],
  },
]

export const COMPANY = [
  { header: '会社名', body: 'バヅクリ株式会社', url: 'https://buzzkuri.co.jp' },
  { header: '代表者名', body: '佐藤 太一' },
  { header: '電話番号', body: '03-6822-6613' },
  {
    header: '住所',
    body: '〒107-0062 東京都港区南青山3-3-3\nリビエラ南青山ビルA WORKING PARK EN',
  },
  { header: '資本金', body: '100,000,000円' },
  { header: '設立', body: '2013年6月3日' },
]

export const SHAREHOLDER = {
  header: '主な株主',
  body: 'COENT VENTURE PARTNERS PTE. LTD.\nEEIスマートエナジー投資事業有限責任組合\n株式会社セゾン・ベンチャーズ\n池田泉州キャピタルニュービジネスファンド5号投資事業有限責任組合\n静岡キャピタル7号投資事業有限責任組合\n九州アントレプレナークラブ2号投資事業有限責任組合\nHRテック投資事業有限責任組合\nみずほ成長支援第4号投資事業有限責任組合\nTSV1号投資事業有限責任組合\nFFGベンチャー投資事業有限責任組合第2号\nZFP第1号投資事業有限責任組合\nアコード・ダーウィン投資事業有限責任組合',
}

export const CATEGORIES = [
  {
    title: '内定者・\n学生向け',
    description: '内定後の初顔合わせに',
    imagePath:
      '/clubs/programs/targets/1/images/job_offers_and_students_v1.jpg',
  },
  {
    title: '新卒・\n若手社員向け',
    description: '社内研修に',
    imagePath:
      '/clubs/programs/targets/2/images/new_graduates_and_young_employees_v1.jpg',
  },
  {
    title: '経営幹部向け',
    description: '本音を語り仲を深める',
    imagePath: '/clubs/programs/targets/4/images/executive_management_v1.jpg',
  },
  {
    title: '全社員向け',
    description: 'タテ・ヨコ・ナナメの交流に',
    imagePath: '/clubs/programs/targets/6/images/all_employees_v1.jpg',
  },
]
