import { ProgramNavListContext } from '@context/ProgramNavList/Cotenxt'
import { useContext } from 'react'
import { ProgramExtended } from './Extended'

export const MenuContentProgram = (): JSX.Element => {
  const { totalProgramCount } = useContext(ProgramNavListContext)

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-center lg:justify-start px-[9vw] lg:px-[2.7vw]">
        <div className="text-[4.3vw] lg:text-[1.3vw] font-bold text-[#333333] lg:text-[#FAFAFA]">
          プログラム数
          <span className="text-[5.3vw] lg:text-[1.6vw]">
            {' '}
            {totalProgramCount}
          </span>
          種類
        </div>
      </div>
      <div className="mt-[5.1vw] lg:mt-[1.6vw]">
        <ProgramExtended />
      </div>
    </div>
  )
}
