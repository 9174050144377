import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export const usePageview = (): void => {
  // Google Tag Manager start
  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })
    }
  }, [])
  // Google Tag Manager end
}
