import styled from 'styled-components'

export const EngagementSurveyWrapper = styled.div`
  text-align: right;
  margin-bottom: 8px;
  a {
    font: normal normal bold 14px/24px YuGothic;
    letter-spacing: 0px;
    color: #aaaaaa;
    opacity: 1;
    display: inline-block;
  }
`

export const LinkIcon = styled.img`
  width: 16px;
  height: 16px;
  display: inline-block;
`
