type Props = {
  text: string
  classes?: string
}

export const PreWraper = ({ text, classes }: Props): JSX.Element => (
  <pre className={`font-body whitespace-pre-wrap ${classes ? classes : ''}`}>
    {text}
  </pre>
)
