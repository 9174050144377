import { InformationRequests } from '@components/buzzkuri/pages/top/InformationRequests'
import { TrialBanner } from '@components/ui/banner/TrialBanner'
import { Business } from '@type/buzzkuri/common'
import React from 'react'
import { Footer } from '../../common/Footer'
import { BuzzkuriHeader } from '../../common/Header'

type Props = {
  business: Business
  isTopPage?: boolean
  children: React.ReactNode
}

export const BaseLayout = ({
  business,
  isTopPage,
  children,
}: Props): JSX.Element => (
  <div className="flex flex-col justify-between items-center min-h-screen font-body">
    <BuzzkuriHeader {...{ business }} />
    {children}
    {!business?.id && (
      <aside>
        {isTopPage && <TrialBanner />}
        <InformationRequests />
      </aside>
    )}
    <footer className="lg:mt-[8.4vw] w-full">
      <Footer {...{ business }} />
    </footer>
  </div>
)
