import { HEADER_NAV_ITEMS } from '@utils/constants'
import { BuzzkuriHeaderNavListItem } from './ListItem'
import { BUSINESS_USER_PAGE } from '@constants/buzzkuri/home'
import { DocumentRequestBtn } from '@components/buzzkuri/shared/DocumentRequestBtn'
import { Business } from '@type/buzzkuri/common'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { HoverMenu } from './HoverMenu'
import { EngagementSurveyWrapper, LinkIcon } from './styled'
import { cloudfrontPath } from '@utils/image'

const LONGIN_NAV = {
  text: 'ログイン',
  href: `/business/login`,
}

type Props = {
  business: Business
}

export const BuzzkuriHeaderPcNav = ({ business }: Props): JSX.Element => {
  return (
    <div>
      {(business?.userSurveyRole === 'admin' ||
        business?.userSurveyRole === 'owner') &&
        business?.surveyServiceStatus && (
          <EngagementSurveyWrapper>
            <a
              href={business.surveyAdminHomeUrl}
              target="_blank"
              rel="noreferrer"
            >
              エンゲージメントに切替
              <LinkIcon
                src={`${cloudfrontPath}/images/icon/arrow-square-out.png`}
              />
            </a>
          </EngagementSurveyWrapper>
        )}
      <nav>
        <ol className="flex flex-row gap-x-[2vw] justify-around items-center text-[.94vw] font-bold">
          {Object.entries(HEADER_NAV_ITEMS).map(([key, item]) =>
            key === 'service' ? (
              <HoverMenu
                text={item.text}
                menuList={HEADER_NAV_ITEMS['service'].subNav}
                key={key}
              />
            ) : key === 'about' ? (
              <HoverMenu
                text={item.text}
                menuList={HEADER_NAV_ITEMS['about'].subNav}
                key={key}
              />
            ) : (
              <div className="" key={key}>
                <BuzzkuriHeaderNavListItem
                  text={item.text}
                  href={item.href}
                  target={item?.target}
                />
              </div>
            )
          )}
          {business?.userId && business?.membershipServiceAvailable ? (
            <li>
              <div className="flex justify-center items-center ml-[3.2vw]">
                <a
                  className="w-[40px]"
                  href={`${BUSINESS_USER_PAGE}/${business.userId}`}
                >
                  <ImageWraper
                    className="rounded-[50%]"
                    src={business.userImage}
                    alt="ユーザーアイコン"
                    width={40}
                    height={40}
                  />
                </a>
              </div>
            </li>
          ) : (
            <>
              <BuzzkuriHeaderNavListItem
                text={LONGIN_NAV.text}
                href={LONGIN_NAV.href}
                target="_self"
              />
              <li>
                <div className="w-[185px] h-[40px]">
                  <DocumentRequestBtn
                    title="無料資料ダウンロード"
                    customDataAttribute={{
                      attributeName: 'data-document-download',
                      attributeValue: 'ヘッダー',
                    }}
                    classes="rounded-[50px] text-[14px]"
                  />
                </div>
              </li>
            </>
          )}
        </ol>
      </nav>
    </div>
  )
}
