export const MY_PAGE_USER_NAVIGATION = [
  {
    text: '開催予定のイベント',
    path: (id: number): string => `/business/memberships/users/${id}/events`,
  },
  {
    text: 'リクエスト中のイベント',
    path: (businessId: number): string =>
      `/business/memberships/${businessId}/event_requests?requests=true`,
  },
  {
    text: '過去に開催したイベント',
    path: (id: number): string =>
      `/business/memberships/users/${id}/events?finished=true`,
  },
  {
    text: 'プロフィール変更',
    path: (id: number): string => `/business/memberships/users/${id}/edit`,
  },
  {
    text: 'メンバー管理',
    path: `/business/memberships/users`,
  },
  { text: 'パスワード変更', path: (id: number): string => `/business/memberships/users/${id}/password/edit` },
]
