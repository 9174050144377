import {
  PROGRAM_CATEGORY_LIST_KEYS_NAME,
  ProgramCategoryListKeysName,
} from '@constants/buzzkuri/programs'
import { ProgramNavListContext } from '@context/ProgramNavList/Cotenxt'
import {
  ProgramsPaths,
  ProgramsPathsContext,
  PROGRAMS_PATHS_KEYS,
} from '@context/ProgramsPaths/Context'

import React, { useContext, useState, useEffect } from 'react'
import { ProgramExtendedHeader } from './Header'
import { ProgramExtendedNavList } from './NavList'
import { ProgramExtendedSearchBox } from './SearchBox'
import { ImageWraper } from '@components/shared/wraper/ImageWraper'
import { BREAKPOINTS } from '@constants/common'
import { WindowSizeContext } from '@context/WindowSize/Context'
import { cloudfrontPath } from '@utils/image'
import Link from 'next/link'

const EXTENDED_STATE = [false, false, false]

export const ProgramExtended = (): JSX.Element => {
  const [selected, setSelected] = useState<boolean[]>([])
  const { programsPaths } = useContext(ProgramsPathsContext)
  const { programCategories } = useContext(ProgramNavListContext)
  const winSize = useContext(WindowSizeContext)
  useEffect(() => {
    if (!selected) {
      let initialState = Object.values(programsPaths).map((str) => Boolean(str))
      initialState =
        initialState.filter((bool) => bool).length > 0
          ? initialState
          : EXTENDED_STATE
      setSelected(initialState)
    }
  }, [programsPaths, selected])

  useEffect(() => {
    setSelected([
      Boolean(programsPaths.objective),
      Boolean(programsPaths.genre && programsPaths.genre !== '体験会'),
      Boolean(programsPaths.targetGroup),
    ])
  }, [programsPaths])

  return (
    <>
      <div className="px-[9vw] lg:px-[2.7vw]">
        {selected &&
          programCategories &&
          Object.entries(programCategories)
            .filter(([key]) => key in PROGRAM_CATEGORY_LIST_KEYS_NAME)
            .map(([key, value], i) => ({
              headerItem: {
                text: PROGRAM_CATEGORY_LIST_KEYS_NAME[
                  key as keyof ProgramCategoryListKeysName
                ],
                isOpen: selected[i],
                idx: i,
              },
              navList: value.map((obj) => obj.name),
            }))
            .map(({ headerItem, navList }, i) => (
              <div key={i}>
                <ProgramExtendedHeader
                  headerItem={headerItem}
                  setState={setSelected}
                />
                {selected[i] && (
                  <ProgramExtendedNavList
                    navList={navList}
                    updateKey={PROGRAMS_PATHS_KEYS[i] as keyof ProgramsPaths}
                  />
                )}
              </div>
            ))}
      </div>
      <div className="px-[9vw] lg:px-[2.7vw]">
        <div
          className={`mb-[27px] border-t-2 ${
            winSize?.width > BREAKPOINTS.laptop
              ? 'border-white'
              : 'border-black'
          } opacity-40`}
        ></div>
        <div className={'mb-[4.4vw] lg:mb-[2.2vw]'}>
          <Link href="/programs/研修">
            <a className="w-full lg:hover:opacity-70">
              <div className="flex flex-row lg:flex-row-reverse justify-between lg:justify-end items-center w-full">
                <div className="ml-0 lg:ml-[0.7vw]">
                  <div className="text-[4.8vw] lg:text-[1.4vw] font-bold text-[#333333] lg:text-[#FAFAFA]">
                    研修から探す
                  </div>
                </div>
                <div className={'rotate-90 lg:rotate-90'}>
                  <div className="w-[7.5vw] lg:w-[1.6vw] ">
                    {winSize?.screenTarget && (
                      <ImageWraper
                        className={
                          winSize?.width <= BREAKPOINTS.laptop
                            ? 'rotate-90'
                            : ''
                        }
                        src={
                          winSize?.width > BREAKPOINTS.laptop
                            ? `${cloudfrontPath}/programs/icons/top/ellipse_arrow.svg`
                            : `${cloudfrontPath}/programs/icons/top/ellipse_arrow_orange.svg`
                        }
                        alt="子メニュー表示切り替えアイコン"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div>
        <div className={'mb-[4.4vw] lg:mb-[2.2vw]'}>
          <Link href="/programs/体験会">
            <a className="w-full lg:hover:opacity-70">
              <div className="flex flex-row lg:flex-row-reverse justify-between lg:justify-end items-center w-full">
                <div className="ml-0 lg:ml-[0.7vw]">
                  <div className="text-[4.8vw] lg:text-[1.4vw] font-bold text-[#333333] lg:text-[#FAFAFA]">
                    体験会から探す
                  </div>
                </div>
                <div className={'rotate-90 lg:rotate-90'}>
                  <div className="w-[7.5vw] lg:w-[1.6vw]">
                    {winSize?.screenTarget && (
                      <ImageWraper
                        className={
                          winSize?.width <= BREAKPOINTS.laptop
                            ? 'rotate-90'
                            : ''
                        }
                        src={
                          winSize?.width > BREAKPOINTS.laptop
                            ? `${cloudfrontPath}/programs/icons/top/ellipse_arrow.svg`
                            : `${cloudfrontPath}/programs/icons/top/ellipse_arrow_orange.svg`
                        }
                        alt="子メニュー表示切り替えアイコン"
                        width={20}
                        height={20}
                      />
                    )}
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div>
      </div>
      <div className="px-[6.9vw] lg:px-[2.7vw]">
        <ProgramExtendedSearchBox />
      </div>
    </>
  )
}
