import { createContext, Dispatch, SetStateAction, useContext } from 'react'

export type ProgramsPaths = {
  objective: string | undefined
  targetGroup: string | undefined
  genre: string | undefined
}

export type ProgramsPathsContextType = {
  programsPaths: ProgramsPaths
  setProgramsPaths: Dispatch<SetStateAction<ProgramsPaths>>
  init: () => void
}

const noop = () => {
  /** */
}

export const DEFAULT_PROGRAMS_PATHS = {
  objective: undefined,
  genre: undefined,
  targetGroup: undefined,
}

const defaultValues: ProgramsPathsContextType = {
  programsPaths: {
    objective: undefined,
    genre: undefined,
    targetGroup: undefined,
  },
  setProgramsPaths: noop,
  init: noop,
}

export const PROGRAMS_PATHS_KEYS = Object.keys(defaultValues.programsPaths)

export const ProgramsPathsContext =
  createContext<ProgramsPathsContextType>(defaultValues)

export function useProgramsPaths(): ProgramsPathsContextType {
  return useContext(ProgramsPathsContext)
}
