import Link from 'next/link'

type Props = {
  description: string
  url: string
  isLast: boolean
}

export const BreadcrumbItem = ({
  description,
  url,
  isLast,
}: Props): JSX.Element => (
  <>
    {isLast ? (
      <div className="text-[3.2vw] lg:text-[12px] text-[#666666]">
        {description}
      </div>
    ) : (
      <Link href={url}>
        <a className="text-[3.2vw] lg:text-[12px] text-[#EB8435]">
          {description}
        </a>
      </Link>
    )}
    {!isLast && (
      <div className="mx-[1vw] lg:mx-[.25vw] text-[3.2vw] lg:text-[12px] text-[#666666]">
        {'>'}
      </div>
    )}
  </>
)
