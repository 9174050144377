import { FREE_TRIAL_LINK } from '@constants/common'
import { useCertainScrolling } from '@hook/useCertainScrolling'

export const TrialBanner = (): JSX.Element => {
  const { isShow } = useCertainScrolling()

  return (
    <>
      {isShow && (
        <div className="fixed lg:top-[calc(50%-239px/2)] right-0 bottom-[24px] z-50 animate-fade-in">
          <div className="w-[55px] h-[239px] bg-gradient-to-b from-primaryTo to-primaryFrom rounded-l-[10px] shadow-button lg:hover:opacity-80">
            <a href={FREE_TRIAL_LINK} target="_blank" rel="noreferrer">
              <div className="flex justify-center items-center h-full">
                <div className="text-[16px] font-bold tracking-[4px] text-white writing-mode">
                  バヅクリを無料で体験
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
    </>
  )
}
