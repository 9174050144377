type Props = {
  text: string
  isSelected: boolean
}

export const BuzzkuriSpMenuHeaderItem = ({
  text,
  isSelected,
}: Props): JSX.Element => {
  return (
    <div
      className={`flex justify-center items-center w-[33.9vw] h-[10.7vw] rounded-[4px] ${
        isSelected ? 'bg-[#F0B430] text-white' : 'bg-white text-[#F0B430]'
      }`}
    >
      <div className="text-[4.3vw] font-bold easing-up">{text}</div>
    </div>
  )
}
