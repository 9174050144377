import { useMemo } from 'react'
import { useBreadcrumbItems } from './hook'
import { BreadcrumbItem } from './Item'
import { BreadcrumbJsonLd } from 'next-seo'
import { getDomain } from '@utils/http'

type Props = {
  currentPathName?: string
  nonNominal?: boolean
}

export const BuzzkuriBreadcrumb = ({
  currentPathName,
  nonNominal,
}: Props): JSX.Element => {
  const breadcrumbItems = useBreadcrumbItems({ currentPathName, nonNominal })
  const itemListElements = useMemo(
    () =>
      breadcrumbItems.map((obj, i) => ({
        position: i + 1,
        name: obj.description,
        item: `https://${getDomain}${obj.url}`,
      })),
    [breadcrumbItems]
  )

  return (
    <>
      <BreadcrumbJsonLd {...{ itemListElements }} />
      <div className="flex justify-start w-full">
        <div className="flex flex-row flex-wrap items-center">
          {breadcrumbItems.map((item, i, arr) => {
            const isLast = arr.length === i + 1
            return (
              <div key={i} className="flex flex-row items-center">
                <BreadcrumbItem {...item} {...{ isLast }} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
