import { getScreenTarget } from '@utils/functions'
import { ReactNode, useEffect, useState } from 'react'
import { WindowSize, WindowSizeContext } from './Context'

type Props = {
  children: ReactNode
}

export function WindowSizeProvider({ children }: Props): JSX.Element {
  const [winSize, setWinSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    screenTarget: 'pc',
  })
  useEffect(() => {
    function handleResize() {
      setWinSize({
        width: window.innerWidth,
        height: window.innerHeight,
        screenTarget: getScreenTarget(window.innerWidth),
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <WindowSizeContext.Provider value={winSize}>
        {children}
      </WindowSizeContext.Provider>
    </>
  )
}
