import { FOOTER_LEFT_NAV_ITEMS } from '@constants/buzzkuri/home'

export const FooterNav = (): JSX.Element => {
  return (
    <div className="text-[3.7vw] lg:text-[1vw]">
      <div className="flex flex-col md:flex-row justify-center mx-[6.4vw] lg:mx-[7.1vw]">
        {FOOTER_LEFT_NAV_ITEMS.map((item, i) => (
          <div
            className={`${i > 0 ? 'lg:ml-[5.3vw] mt-[9vw] lg:mt-0' : ''}`}
            key={i}
          >
            <span className="text-[#A5A5A1]">{item.category}</span>
            <div className="flex flex-col lg:flex-row">
              <div>
                <ul className="relative pl-[3.7vw] lg:pl-[1vw] mt-[5.6vw] lg:mt-[1.6vw] footer-gradient-border">
                  {item.navItems.map((nav, i) => (
                    <li
                      key={nav.text}
                      className={i > 0 ? 'lg:mt-[1.5vw] mt-[5.8vw]' : ''}
                    >
                      <a href={nav.href} target={nav.target}>
                        {nav.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              {item?.subNabItems && (
                <ul className="lg:after:hidden relative lg:static pt-[5.8vw] lg:pt-0 pl-[3.7vw] lg:pl-0 lg:mt-[1.6vw] lg:ml-[2.9vw] footer-gradient-border">
                  {item.subNabItems.map((item, i) => (
                    <li
                      key={i}
                      className={i > 0 ? 'lg:mt-[1.5vw] mt-[5.8vw]' : ''}
                    >
                      <a href={item.href} target={item.target}>
                        {item.text}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
