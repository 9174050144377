import { logout } from '@utils/http'

export const LogoutNavItem = (): JSX.Element => (
  <>
    {/* NavigationList class tailwind */}
    <div className="mt-[4vw] font-normal">
      {/* NavigationListItem class tailwind */}
      <button
        className="flex justify-start items-center h-[10vw] text-[4.3vw] text-[#333333] bg-white lg:hover:opacity-70 easing-up__txt"
        onClick={logout}
      >
        ログアウト
      </button>
    </div>
  </>
)
